import * as React from "react";
import { Helmet } from "react-helmet";
import SucessPage from "../components/successPage/successPage";

import SEO from "../components/seo/SEO";
import Layout from "../components/layout/Layout";

export default function NoActiveAccount({ location }) {
  const params = new URLSearchParams(location?.search);
  const error = params.get("error");
  const redirect = params.get("redirect");
  if (redirect) setTimeout(() => (document.location = redirect), 2800);
  return (
    <Layout>
      <SEO />
      <Helmet>
        <title>Error - /meet 4 Slack</title>
      </Helmet>
      <>
        <div className="bg-white min-h-full px-8 pt-48 pb-16 sm:px-12 md:grid md:place-items-center">
          {error}
        </div>
      </>
    </Layout>
  );
}
